<template>
  <dashboard-content full-width>
    <page-header sticky>
      <h1 class="mb-4">
        Reminders
      </h1>
    </page-header>
    <v-row>
      <v-col class="">
        <v-card>
          <v-card-title>
            <v-text-field
              v-model="search"
              :append-icon="mdiMagnify"
              label="Search"
              single-line
              hide-details
            />
          </v-card-title>
          <v-card-subtitle>
            <v-select
              v-model="limit"
              label="Limit"
              :items="[1000, 5000, 'All']"
              @change="fetchReminders()"
            />
          </v-card-subtitle>
          <v-data-table
            mobile-breakpoint="1140"
            :headers="headers"
            :items="items"
            :search="search"
            :sort-field="sortField"
            :loading="loading"
          >
            <template v-slot:item.createdAt="{ item }">
              {{ formatDatetimeLocal(item.createdAt) }}
            </template>
            <!-- combine first and last name for display -->
            <template v-slot:item.first_name="{ item }">
              {{ item.first_name + ' ' + item.last_name }}
            </template>
            <template v-slot:item.action="{ item }">
              <span class="no-wrap">
                <v-icon
                  small
                  class="mr-2"
                  @click="showItem(item.id)"
                >
                  {{ mdiEye }}
                </v-icon>
                <v-icon
                  small
                  @click="removeItem(item.id)"
                >
                  {{ mdiClose }}
                </v-icon>
              </span>
            </template>
          </v-data-table>
        </v-card>
      </v-col>

      <confirm-modal
        v-if="selectedItemId"
        :dialog="confirmDialog"
        @agree="remove"
        @disagree="confirmDialog = false"
      />
    </v-row>
  </dashboard-content>
</template>

<script>
import { mapGetters } from 'vuex'
import { mdiEye, mdiPlus, mdiPencil, mdiClose, mdiMagnify } from '@mdi/js'
import DashboardContent from '@/components/dashboard/DashboardContent'
import ConfirmModal from '@/components/ConfirmModal'
// import reminders from '@/kit/dictionaries/reminders'
import getContact from '@/kit/helpers/contact'
import PageHeader from '@/components/dashboard/PageHeader.vue'

export default {
  components: {
    ConfirmModal,
    DashboardContent,
    PageHeader
  },
  data() {
    return {
      mdiEye,
      mdiClose,
      mdiMagnify,
      mdiPencil,
      mdiPlus,
      getContact,
      headers: [
        { text: 'ID', value: 'id' },
        { text: 'Created at', value: 'createdAt' },
        { text: 'Reminder', value: 'reminder' },
        { text: 'Title', value: 'activity.title' },
        { text: 'Name', value: 'name' },
        { text: 'Contact', value: 'contact' },
        {
          text: 'Notification type',
          value: 'activity.notificationConfig.type'
        },
        { text: 'Actions', value: 'action', sortable: false }
      ],
      confirmDialog: false,
      selectedItemId: null,
      preparedCollection: null,
      counterPath: null,
      counterField: null, 
      search: '', 
      sortField: 'createdAt',
      items: [],
      loading: false,
      limit: 1000
    }
  },
  computed: {
    ...mapGetters('authentication', ['user', 'isProvider'])
  },
  async mounted() {
    if (this.isProvider) {
      this.counterPath = `users/${this.user.id}`
      this.counterField = 'countReminders'
      this.items = this.$firebase
        .firestore()
        .collection('schedules')
        .where('practice', '!=', true)
        .where('createdBy', '==', this.user.id)
    } else {
      this.counterPath = 'counters/schedules'
      this.fetchReminders()
    }
  },
  methods: {
    async fetchReminders() {
      this.loading = true
      const q = await this.$firebase.firestore()
        .collection('schedules')
        .where('practice', '!=', true)
      let qRef = null
      if (this.limit !== 'All') {
        qRef = q.limit(this.limit)
      } else {
        qRef = q
      }
      qRef.get()
        .then(snapshot => {
          snapshot.forEach(item => {
            this.items.push({id: item.id, ...item.data()})
          })
        })
      this.loading = false
    },
    showItem(id) {
      this.$router.push({ name: 'reminderShow', params: { id } })
    },
    removeItem(id) {
      this.selectedItemId = id
      this.confirmDialog = true
    },
    remove() {
      const query = this.$firebase.firestore().collection('schedules')
      query
        .doc(this.selectedItemId)
        .delete()
        .then(() => {
          this.confirmDialog = false
          this.$notify({
            text: 'Successfully deleted'
          })
        })
        .catch(() => {
          this.confirmDialog = false
          this.$notify({
            type: 'err',
            text: 'Error occurred during delete'
          })
        })
    }
    // getReminder(id) {
    //   return reminders[0].reminders.find(item => item.id === id)
    // }
  }
}
</script>
